<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('confirmation') }}
				</div>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-12 col-md-6">
				<ul class="list-group w-100 h-100">
					<li class="list-group-item h-100">
						<div class="row">
							<div class="col">
								<div class="h5 mb-0">
									{{ translate('payment_step') }}
								</div>
								<small>{{ translate(paymentMethod.name) }}</small>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'payment') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<template v-if="!loadingBillingAddress && billing.country_code !== ''">
							<div class="row mt-3">
								<div class="col-auto">
									<p class="mb-0">
										<b>{{ billing.first_name }} {{ billing.last_name }}</b>
									</p>
									<p class="mb-0">
										{{ billing.address }}
									</p>
									<p class="mb-0">
										{{ billing.address2 }}
									</p>
									<p class="mb-0">
										{{ billing.city }}, {{ billing.region_id }}, {{ billing.postcode }}
									</p>
									<p class="mb-0">
										{{ translate(billing.country_code.toLowerCase()) }}
									</p>
								</div>
							</div>
							<div
								v-if="CREDIT_CARD_FORM_METHODS.includes(paymentMethod.name)"
								class="row mt-1">
								<div class="col-auto">
									<p class="mb-0">
										<img
											:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
											class="my-auto mr-1"
											style="width:45px; height:30px;">
										<span class="text-muted"> ****{{ paymentMethod.last_digits.length > 0 ? paymentMethod.last_digits : '' }}</span>
									</p>
								</div>
							</div>
						</template>
						<div
							v-else
							class="row">
							<div
								class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
								<div class="col-12">
									<div class="h2">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<div class="row h-100">
					<div class="col-12">
						<li class="list-group-item p-0 rounded">
							<div class="table-responsive my-0">
								<is-loading
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loadingCartTotals"
									:has-data="true"
									:class="loadingCartTotals ? 'mt-3' : ''" />
								<table
									v-if="!loadingCartTotals && hasCartTotals"
									class="table table-borderless m-0">
									<thead>
										<tr class="h5 bg-white text-gray-dark">
											<th colspan="4">
												{{ translate('product_details') }}
											</th>
										</tr>
									</thead>
									<thead>
										<tr class="text-primary text-center border-bottom bg-white">
											<th class="text-left">
												{{ translate('product') }}
											</th>
											<th>
												{{ translate('qty') }}
											</th>
											<th class="text-small">
												{{ translate('bv') }}
											</th>
											<th class="text-right">
												{{ translate('subtotal') }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in cartTotals.products"
											:key="item.item_id">
											<td class="text-left font-weight-bolder pb-0">
												{{ translate(item.code_name) }}
												<a
													v-if="productHasLink(item.sku)"
													:href="productsLinks[item.sku]"
													target="_blank"
													class="text-decoration-none link-primary">
													<i class="fas fa-info-circle" />
												</a>
											</td>
											<td class="text-center pb-0">
												{{ item.qty }}
											</td>
											<td class="text-center pb-0">
												{{ item.total_volume }}
											</td>
											<td class="text-right pb-0">
												{{ item.sub_total }}
											</td>
										</tr>
										<tr
											v-for="(item, index) in cartTotals.totals"
											:key="index">
											<td
												colspan="3"
												:class="{
													'font-weight-bolder': !smallTextTotals.includes(index),
												}"
												class="pb-0 pt-2 text-right">
												{{ translate(index) }}
											</td>
											<td class="pb-0 pt-2 text-right">
												{{ item }}
											</td>
										</tr>
										<tr>
											<td
												colspan="3"
												class="pb-1 pb-3 font-weight-bolder text-right text-primary h5">
												{{ translate('total') }}
											</td>
											<td class="pb-1 pb-3 text-right text-primary h5">
												{{ cartTotals.total }}
												<span
													v-if="typeof cartTotals.exchange !== 'undefined' && cartTotals.exchange.is_needed"
													class="font-weight-normal exchange-text"
													style="display: block; font-size:11px;">
													{{ cartTotals.exchange.total }}*
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p
								v-if="typeof cartTotals.exchange !== 'undefined' && cartTotals.exchange.is_needed"
								class="exchange-text text-right small mt-2 px-2">
								{{ translate('exchange_disclaimer') }}
							</p>
						</li>
					</div>
					<div class="col-12 align-self-end">
						<li
							class="h-auto border-0 px-0 pt-2 d-flex">
							<div class="form-check mt-2">
								<input
									id="policyNProcedures"
									v-model="disclaimer"
									name="policyNProcedures"
									class="form-check-input"
									type="checkbox"
									value="">
								<label
									class="form-check-label"
									for="policyNProcedures"
									v-html="translate('policies_and_procedures_agreement', {policies_and_procedures: `<a target='_blank' href='${TERMS_AND_CONDITIONS_REDIRECTION}'>${ translate('policies_and_procedures') }</a>`})" />
							</div>
						</li>
						<div
							class="btn-group w-100"
							role="group">
							<b-button
								:disabled="!disclaimer"
								variant="primary"
								size="lg"
								block
								class="mt-3"
								@click="goSuccess">
								{{ translate('confirm') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import isLoading from '@/components/Loading';
import DocumentTitle from '@/mixins/DocumentTitle';
import { PRODUCTS_LINKS } from '@/settings/Products';
import { AVAILABLECC as availableCC, CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import { INTERNAL_SERVER_ERROR, UNPROCESSABLE } from '@/settings/Errors';
import { TERMS_AND_CONDITIONS_REDIRECTION } from '@/settings/General';
import Country from '@/util/Country';
import AddressBook from '@/util/AddressBook';
import Cart from '@/util/Cart';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from './mixins/Common';

export default {
	name: 'RenewalConfirmation',
	components: { isLoading },
	mixins: [CommonMix, DocumentTitle, WindowSizes],
	data() {
		return {
			cart: new Cart(),
			productsLinks: PRODUCTS_LINKS,
			alert: new this.$Alert(),
			stateData: new Country(),
			loadBillingAddress: new AddressBook(),
			idempotencyId: '',
			disclaimer: '',
			shipping: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				country_code: '',
			},
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
			},
			TERMS_AND_CONDITIONS_REDIRECTION,
			CREDIT_CARD_FORM_METHODS,
		};
	},
	computed: {
		loadingCartTotals() {
			try {
				return this.cart.data.loading;
			} catch (error) {
				return true;
			}
		},
		cartTotals() {
			try {
				return this.cart.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		smallTextTotals() {
			try {
				return this.cartTotals.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		hasCartTotals() {
			return !!Object.keys(this.cartTotals);
		},
		cardLogo() {
			if (this.paymentMethod.card_type.length > 0 && availableCC.includes(this.paymentMethod.card_type)) {
				return this.paymentMethod.card_type;
			}
			return 'cc';
		},
		loadingBillingAddress() {
			return !!this.loadBillingAddress.data.loading;
		},
	},
	created() {
		this.idempotencyId = this.createUuid();
		Object.keys(this.steps).forEach((key) => {
			if (key !== this.$route.name) {
				const previous = this.getStepInformation(key);
				const step = 'RenewalPaymentMethod';
				if (step === key) {
					try {
						const { billing: billingAddress } = previous.payment;
						if (typeof billingAddress === 'undefined') {
							this.$router.replace({ name: key });
							return null;
						}
						const paymentMethod = previous.card_data;
						Object.keys(paymentMethod).forEach((element) => {
							this.paymentMethod[element] = paymentMethod[element];
						});
						this.paymentMethod.name = previous.payment.payment_method.name;
						this.setBillingAddress(billingAddress.address_id);
						if (billingAddress.address_id.length === 0) {
							this.stateData.getState(this.billing.region_id).then((state) => {
								this.billing.region_id = state.attributes.name;
							});
						}
						this.billing.country_code = this.billing.country_code.toLowerCase();
					} catch (error) {
						this.$router.replace({ name: key });
						return null;
					}
				}
			}
			return null;
		});
		if (this.shoppingCartId !== 'undefined' && this.shoppingCartId !== '') {
			this.cart.getCart(this.shoppingCartId);
		}
	},
	methods: {
		setBillingAddress(addressId) {
			this.loadBillingAddress.getAddress(this.$user.details().id, addressId).then((response) => this.setAddressInfo('billing', response.attributes));
		},
		setAddressInfo(addressType, address) {
			this[addressType].first_name = address.first_name;
			this[addressType].last_name = address.last_name;
			this[addressType].address = address.address;
			this[addressType].address2 = address.address2;
			this[addressType].city = address.city;
			this[addressType].postcode = address.postcode;
			this[addressType].country_code = address.country.code;
			this[addressType].region_id = address.region.id;
			this.stateData.getState(this[addressType].region_id).then((state) => {
				this[addressType].region_id = state.attributes.name;
			});
		},
		goSuccess() {
			let payload = {};
			Object.keys(this.steps).forEach((key) => {
				if (key !== this.$route.name) {
					const step = 'RenewalPaymentMethod';
					if (key === step) {
						payload = { ...payload, ...this.getStepInformation(key) };
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
					}
				}
			});
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			const validation = { ...payload, step: this.steps[this.$route.name].validation };
			this.validateStep(validation).then((response) => {
				this.removeRegister().then(() => {
					this.$user.init();

					const {
						redirect_url: redirectUrl,
						// verification_required: verificationRequired,
						crypto_wallet: cryptoWallet,
					} = response.response;
					// Redirect to instructed page
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}

					const routeName = 'RenewalSuccess';
					// Pass payment method to success page in case we need to display an alert for it
					const routeOptions = {
						name: routeName,
						// query: { payment_method: payload.payment.payment_method.name ?? '' },
					};
					// Show verbal verification alert
					// if (verificationRequired) {
					// 	routeOptions.query.verification_required = true;
					// }
					// Go to crypto payment details
					if (cryptoWallet) {
						routeOptions.query = { wallet_address: cryptoWallet };
					}
					// Go to new route
					this.$router.replace(routeOptions);
					// Close 'Processing request...' alert
					this.alert.close();
				}).catch(() => {});
			}).catch((error) => {
				this.idempotencyId = this.createUuid();
				if (UNPROCESSABLE.includes(error.status)) {
					const {
						cart_id: cartId,
						payex,
						'payment.payment_method.name': paymentMethodName,
					} = error.errors;
					if (typeof cartId !== 'undefined') {
						this.saveCart(cartId);
					}
					if (typeof payex !== 'undefined') {
						let response = '';
						payex.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					}
					if (typeof paymentMethodName !== 'undefined') {
						let response = '';
						paymentMethodName.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
					}

					// If the validation error wasn't one of the above
					if ([cartId, payex, paymentMethodName].every((field) => typeof field === 'undefined')) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					}
				} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
					setTimeout(() => { this.$router.replace({ name: Object.keys(this.steps)[0] }); }, 2500);
				}
			});
			return null;
		},
		productHasLink(key) {
			return typeof this.productsLinks[key] === 'string' || this.productsLinks[key] instanceof String;
		},
	},
};
</script>
